import * as _blakejs2 from "blakejs";

var _blakejs = "default" in _blakejs2 ? _blakejs2.default : _blakejs2;

var exports = {};
// @ts-ignore - no types available
const blake = _blakejs;
const minB = 45569;
const minS = 45633;
const blake2b = {
  init: blake.blake2bInit,
  update: blake.blake2bUpdate,
  digest: blake.blake2bFinal
};
const blake2s = {
  init: blake.blake2sInit,
  update: blake.blake2sUpdate,
  digest: blake.blake2sFinal
}; // Note that although this function doesn't do any asynchronous work, we mark
// the function as async because it must return a Promise to match the API
// for other functions that do perform asynchronous work (see sha.browser.js)
// eslint-disable-next-line

/**
 * @param {number} size
 * @param {any} hf
 * @returns {import('./types').Digest}
 */

const makeB2Hash = (size, hf) => async data => {
  const ctx = hf.init(size, null);
  hf.update(ctx, data);
  return hf.digest(ctx);
};
/**
 * @param {Record<number, import('./types').Digest>} table
 */


exports = table => {
  for (let i = 0; i < 64; i++) {
    table[minB + i] = makeB2Hash(i + 1, blake2b);
  }

  for (let i = 0; i < 32; i++) {
    table[minS + i] = makeB2Hash(i + 1, blake2s);
  }
};

export default exports;