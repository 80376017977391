import * as _errCode2 from "err-code";

var _errCode = "default" in _errCode2 ? _errCode2.default : _errCode2;

import * as _multihashes2 from "multihashes";

var _multihashes = "default" in _multihashes2 ? _multihashes2.default : _multihashes2;

import _crypto from "./crypto";
import * as _equals2 from "uint8arrays/equals";

var _equals = "default" in _equals2 ? _equals2.default : _equals2;

var exports = {};
const errcode = _errCode;
const multihash = _multihashes;
const crypto = _crypto;
const {
  equals
} = _equals;
/**
 * @typedef {import("./types").Digest} Digest
 * @typedef {import("multihashes").HashName} HashName
 */

/**
 * Hash the given `bytes` using the algorithm specified by `alg`.
 *
 * @param {Uint8Array} bytes - The value to hash.
 * @param {HashName} alg - The algorithm to use eg 'sha1'
 * @param {number} [length] - Optionally trim the result to this length.
 * @returns {Promise<Uint8Array>}
 */

async function Multihashing(bytes, alg, length) {
  const digest = await Multihashing.digest(bytes, alg, length);
  return multihash.encode(digest, alg, length);
}
/**
 * Expose multihash itself, to avoid silly double requires.
 */


Multihashing.multihash = multihash;
/**
 * @param {Uint8Array} bytes - The value to hash.
 * @param {HashName} alg - The algorithm to use eg 'sha1'
 * @param {number} [length] - Optionally trim the result to this length.
 * @returns {Promise<Uint8Array>}
 */

Multihashing.digest = async (bytes, alg, length) => {
  const hash = Multihashing.createHash(alg);
  const digest = await hash(bytes);
  return length ? digest.slice(0, length) : digest;
};
/**
 * Creates a function that hashes with the given algorithm
 *
 * @param {HashName} alg - The algorithm to use eg 'sha1'
 * @returns {Digest} - The hash function corresponding to `alg`
 */


Multihashing.createHash = function (alg) {
  if (!alg) {
    const e = errcode(new Error("hash algorithm must be specified"), "ERR_HASH_ALGORITHM_NOT_SPECIFIED");
    throw e;
  }

  const code = multihash.coerceCode(alg);

  if (!Multihashing.functions[code]) {
    throw errcode(new Error(`multihash function '${alg}' not yet supported`), "ERR_HASH_ALGORITHM_NOT_SUPPORTED");
  }

  return Multihashing.functions[code];
};
/**
 * Mapping of multihash codes to their hashing functions.
 *
 * @type {Record<number, Digest>}
 */
// @ts-ignore - most of those functions aren't typed


Multihashing.functions = {
  // identity
  0: crypto.identity,
  // sha1
  17: crypto.sha1,
  // sha2-256
  18: crypto.sha2256,
  // sha2-512
  19: crypto.sha2512,
  // sha3-512
  20: crypto.sha3512,
  // sha3-384
  21: crypto.sha3384,
  // sha3-256
  22: crypto.sha3256,
  // sha3-224
  23: crypto.sha3224,
  // shake-128
  24: crypto.shake128,
  // shake-256
  25: crypto.shake256,
  // keccak-224
  26: crypto.keccak224,
  // keccak-256
  27: crypto.keccak256,
  // keccak-384
  28: crypto.keccak384,
  // keccak-512
  29: crypto.keccak512,
  // murmur3-128
  34: crypto.murmur3128,
  // murmur3-32
  35: crypto.murmur332,
  // dbl-sha2-256
  86: crypto.dblSha2256
}; // add blake functions

crypto.addBlake(Multihashing.functions);
/**
 * @param {Uint8Array} bytes
 * @param {Uint8Array} hash
 * @returns {Promise<boolean>}
 */

Multihashing.validate = async (bytes, hash) => {
  const newHash = await Multihashing(bytes, multihash.decode(hash).name);
  return equals(hash, newHash);
};

exports = Multihashing;
export default exports;