import * as _jsSha2 from "js-sha3";

var _jsSha = "default" in _jsSha2 ? _jsSha2.default : _jsSha2;

import * as _murmurhash3jsRevisited2 from "murmurhash3js-revisited";

var _murmurhash3jsRevisited = "default" in _murmurhash3jsRevisited2 ? _murmurhash3jsRevisited2.default : _murmurhash3jsRevisited2;

import * as _sha2 from "./sha";

var _sha = "default" in _sha2 ? _sha2.default : _sha2;

import _utils from "./utils";
import * as _fromString2 from "uint8arrays/from-string";

var _fromString = "default" in _fromString2 ? _fromString2.default : _fromString2;

import _blake from "./blake";
var exports = {};
const sha3 = _jsSha; // @ts-ignore - no types available

const mur = _murmurhash3jsRevisited;
const {
  factory: sha
} = _sha;
const {
  fromNumberTo32BitBuf
} = _utils;
const {
  fromString: uint8ArrayFromString
} = _fromString; // Note that although this function doesn't do any asynchronous work, we mark
// the function as async because it must return a Promise to match the API
// for other functions that do perform asynchronous work (see sha.browser.js)
// eslint-disable-next-line

/**
 * @param {string} algorithm
 * @returns {import('./types').Digest}
 */

const hash = algorithm => async data => {
  switch (algorithm) {
    case "sha3-224":
      return new Uint8Array(sha3.sha3_224.arrayBuffer(data));

    case "sha3-256":
      return new Uint8Array(sha3.sha3_256.arrayBuffer(data));

    case "sha3-384":
      return new Uint8Array(sha3.sha3_384.arrayBuffer(data));

    case "sha3-512":
      return new Uint8Array(sha3.sha3_512.arrayBuffer(data));

    case "shake-128":
      return new Uint8Array(sha3.shake128.create(128).update(data).arrayBuffer());

    case "shake-256":
      return new Uint8Array(sha3.shake256.create(256).update(data).arrayBuffer());

    case "keccak-224":
      return new Uint8Array(sha3.keccak224.arrayBuffer(data));

    case "keccak-256":
      return new Uint8Array(sha3.keccak256.arrayBuffer(data));

    case "keccak-384":
      return new Uint8Array(sha3.keccak384.arrayBuffer(data));

    case "keccak-512":
      return new Uint8Array(sha3.keccak512.arrayBuffer(data));

    case "murmur3-128":
      return uint8ArrayFromString(mur.x64.hash128(data), "base16");

    case "murmur3-32":
      return fromNumberTo32BitBuf(mur.x86.hash32(data));

    default:
      throw new TypeError(`${algorithm} is not a supported algorithm`);
  }
};
/** @type {import('./types').Digest} */


const identity = data => data;

exports = {
  identity,
  sha1: sha("sha1"),
  sha2256: sha("sha2-256"),
  sha2512: sha("sha2-512"),
  dblSha2256: sha("dbl-sha2-256"),
  sha3224: hash("sha3-224"),
  sha3256: hash("sha3-256"),
  sha3384: hash("sha3-384"),
  sha3512: hash("sha3-512"),
  shake128: hash("shake-128"),
  shake256: hash("shake-256"),
  keccak224: hash("keccak-224"),
  keccak256: hash("keccak-256"),
  keccak384: hash("keccak-384"),
  keccak512: hash("keccak-512"),
  murmur3128: hash("murmur3-128"),
  murmur332: hash("murmur3-32"),
  addBlake: _blake
};
export default exports;